$(document).ready(function () {

    responsive_init();
    icons_load();
    blazy_init();
    img_to_bg();
    click_touch_init();
    toggle_element_init();
    category_banner_init();

    search_toggler_init();
    phone_toggler_init();

    rangeSliderStart();
    filter_update();

    bbar_init();
	
	region_confirm_popover();
});

$(window).on("load", function () {
    plitka_images_more_init();
});

$(document).on("shown.bs.offcanvas", function (e) {
    bodyScrollLock.disableBodyScroll(e.target);
});

$(document).on("hidden.bs.offcanvas", function (e) {
    bodyScrollLock.enableBodyScroll(e.target);
});

$(document).on("shown.bs.modal", function (e) {
    bodyScrollLock.disableBodyScroll(e.target);
});

$(document).on("hidden.bs.modal", function (e) {
    bodyScrollLock.enableBodyScroll(e.target);
});

function plitka_images_more_init() {
    if (!$("body").hasClass("plitka-image-more-inited")) {

        $(document).on("mouseenter", ".plitka-image-more-item", function (e) {
            $(this).closest(".plitka-image").find("img").attr("src", $(this).attr("data-image"));
        });
        $(document).on("mouseleave", ".plitka-image-more", function (e) {
            $(this).closest(".plitka-image").find("img").attr("src", $(this).attr("data-image-default"));
        });

        $("body").addClass("plitka-image-more-inited");
    }

    $(".plitka-image-more").not("plitka-image-more-inited").each(function () {
        var images = $(this).data("images");
        $(this).attr("data-image-default", $(this).closest(".plitka-image").find("img").attr("data-src"));
        if (images && images.length) {
            var $items = $("<div class='plitka-image-more-items'></div>");
            $(this).append($items);
            $.each(images, function (k, v) {
                var $item = $("<div class='plitka-image-more-item'></div>");
                $items.append($item);
                $item.attr("data-image", v);
            });
        }
        $(this).addClass("plitka-image-more-inited");
    });
}

$('body').on('click', '.badge-check-price-by-phone', function() {
	var u = window.location.href;
	var attr_url = $(this).attr('data-purl');
	if (typeof attr !== 'undefined' && attr !== false)
		u = attr_url;
	
	$('#contact-center input[name=user_name]').val(mc_user_name);
    $('#contact-center input[name=call_time]').val('');
    $('#contact-center textarea[name=message]').val('Хочу узнать цену ' + u + '.');

    $('#callback-part').show();
    $('#send-callback').show();
    $('#callback-success-part').hide();
    $('#callback-error-part').hide();
    $('#callback-close').hide();
    $('#contact-center-phone-required').hide();

    $('#contact-center').modal();
    if ($('#contact-center input[name=phone_number]').val() == "")
        $('#contact-center input[name=phone_number]').focus();
    else if ($('#contact-center input[name=user_name]').val() == "")
        $('#contact-center input[name=user_name]').focus();
    else
        $('#contact-center input[name=call_time]').focus();
    return false;
});

/* развернуть категорию */
$('#categories').on('click', 'i.fa-plus', function () {

    var li = $(this).closest('li');

    /* Свернем другие категории */
    $('#categories > ol > li:not(.collapsed) ' + mc_nested_level).each(function () {
        var li_find = $(this).find('li[data-id=' + li.attr('data-id') + ']');
        if (li_find.length == 0) {
            var icon_minus = $(this).find('i.fa-minus');
            if (icon_minus.length > 0)
                icon_minus.trigger('click');
        }
    });

    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');
    var icon_spin = li.find('i.fa-spinner:first');

    icon_exp.hide();

    /* Подгрузим на ajax'e дерево */
    if (ol.length == 0) {
        icon_spin.show();
        print_lazy_result(li, li.attr('data-id'), icon_col, icon_spin);
    } else {
        ol.show();
        icon_col.show();
    }

    li.removeClass('collapsed');
});
$('#popular-categories').on('click', 'i.fa-plus', function () {

    var li = $(this).closest('li');

    /* Свернем другие категории */
    $('#popular-categories > ol > li:not(.collapsed) ' + mc_nested_level).each(function () {
        var li_find = $(this).find('li[data-id=' + li.attr('data-id') + ']');
        if (li_find.length == 0) {
            var icon_minus = $(this).find('i.fa-minus');
            if (icon_minus.length > 0)
                icon_minus.trigger('click');
        }
    });

    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');
    var icon_spin = li.find('i.fa-spinner:first');

    icon_exp.hide();

    /* Подгрузим на ajax'e дерево */
    if (ol.length == 0) {
        icon_spin.show();
        print_lazy_result(li, li.attr('data-id'), icon_col, icon_spin);
    } else {
        ol.show();
        icon_col.show();
    }

    li.removeClass('collapsed');
});

/* свернуть категорию */
$('#categories').on('click', 'i.fa-minus', function () {
    var li = $(this).closest('li');
    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');

    ol.hide();
    li.addClass('collapsed');
    icon_col.hide();
    icon_exp.show();
});
$('#popular-categories').on('click', 'i.fa-minus', function () {
    var li = $(this).closest('li');
    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');

    ol.hide();
    li.addClass('collapsed');
    icon_col.hide();
    icon_exp.show();
});

/*$('body').on('click', 'a', function(e){
	if (e.ctrlKey)	//При зажатом Ctrl открываем ссылку в новом окне
	{
		var win = window.open($(this).attr('href'), '_blank');
		return false;
	}
});*/

/* выбор категории */
$('#categories').on('click', 'a', function (e) {

    /*При зажатом Ctrl открываем ссылку в новом окне*/
    /*if (e.ctrlKey)
    {
        var win = window.open($(this).attr('href'), '_blank');
        return false;
    }*/

    return true;

    var li = $(this).closest('li');
    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');
    var icon_spin = li.find('i.fa-spinner:first');
    var id = li.attr('data-id');
    if (id == undefined)
        return false;
    id = parseInt(id);
    var params = [];
    $('#brands input:checked').each(function () {
        $(this).prop('checked', false);
    });
    if (id > 0) {
        params.push({
            'key': 'url',
            'value': $(this).attr('href')
        });
    } else {
        /* Свернем другие категории */
        $('#categories li:not(.collapsed) ' + mc_nested_level).each(function () {
            var icon_minus = $(this).find('i.fa-minus');
            icon_minus.trigger('click');
        });
    }

    /* Свернем другие категории */
    $('#categories > ol > li:not(.collapsed) ' + mc_nested_level).each(function () {
        var item = $(this).find('li[data-id=' + id + ']');
        if (item.length == 0) {
            var icon_minus = $(this).find('i.fa-minus');
            if (icon_minus.length > 0)
                icon_minus.trigger('click');
        }
    });

    products_request_ajax(params);
    return false;
});

/* нажатие кнопки "Показать еще" */
$('#main-right-container').on('click', 'a.show-more', function (e) {
    var url = $(this).attr('href');

    $('#main-right-container ul.pagination:last').closest('div.row').remove();
    $('#main-right-container a.show-more').remove();

    if (mc_mode.length == 0 || mc_mode == 'list') {
        $('<div class="loading"><div class="loader"></div></div>').insertAfter($('#main-right-container ul.list:last'));
    } else {
        $('<div class="loading"><div class="loader"></div></div>').insertAfter($('#main-right-container div.plitka:last'));
    }

    var params = [{'key': 'url', 'value': url}];
    products_request_ajax(params, undefined, undefined, undefined, false, undefined, true);
    return false;
});

/* переход по страницам */
$('#main-right-container').on('click', 'ul.pagination a', function (e) {
    var url = $(this).attr('href');
    var type = $(this).data('type');
    var page = $(this).data('page');
    if (url == undefined)
        return false;

    if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
    {
        var win = window.open(url, '_blank');
        return false;
    }

    switch (type) {
        case 'category':
            var params = [{'key': 'url', 'value': url}];
            products_request_ajax(params);
            break;
        case 'material-category':
            document.location.href = url;
            //material_request_ajax(url);
            break;
        case 'brand':
            var params = [{'key': 'url', 'value': url}];
            brand_request_ajax(params);
            break;
        case 'collection':
            document.location.href = url;
            return false;
            break;
        case 'account_orders':
            return true;
        case 'account_reviews':
            return true;
        case 'reviews':

            $('html, body').animate({
                scrollTop: $("#reviews-list").offset().top
            }, 0);

            var sort_method = $('#reviews-list div.rate-sort-item.active');

            var mainUrl = mc_product_reviews_paginate_module_url + page;
            if (sort_method.length > 0)
                mainUrl += "&sort=" + sort_method.data('sort');
            if (typeof history.pushState != undefined) {
                history.pushState({mode: 'product', url: mainUrl}, null, encodeURI(decodeURI(mainUrl)));
            }

            $.get(url, function (data) {
                $('#reviews-list').html(data);

                $('#user-rating2-1, #user-rating2-2').rating({
                    fx: 'full',
                    image: mc_path_frontend_template + '/img/stars/big.png',
                    loader: '<i class="fa fa-spinner fa-spin"></i>',
                    minimal: 1,
                    preselectMode: true,
                    click: function (data) {
                        var index = $(this).closest('.my-review').data('index');
                        $('#rating-block-error-' + index).hide();
                        $('#reviews-list input[name=rating]').val(data);
                    }
                });
            });
            break;
    }
    return false;
});

/* сортировка */
$('#main-right-container').on('click', '#sort li a', function () {
    var url = $(this).attr('href');
    var type = $(this).attr('data-type');
    if (url == undefined)
        return false;

    switch (type) {
        case 'category':
            var params = [{'key': 'url', 'value': url}];
            products_request_ajax(params);
            break;
        case 'material-category':
            document.location.href = url;
            //material_request_ajax(url);
            break;
        case 'brand':
            var params = [{'key': 'url', 'value': url}];
            brand_request_ajax(params);
            break;
        case 'account_orders':
            return true;
    }
    return false;
});

/* изменение режима просмотра */
$('#main-right-container').on('click', '#view a', function () {
    var url = $(this).attr('href');
    var type = $(this).attr('data-type');
    if (url == undefined)
        return false;

    switch (type) {
        case 'category':
            var params = [{'key': 'url', 'value': url}];
            products_request_ajax(params);
            break;
        case 'material-category':
            document.location.href = href;
            //material_request_ajax(url);
            break;
        case 'brand':
            var params = [{'key': 'url', 'value': url}];
            brand_request_ajax(params);
            break;
        case 'account_orders':
            return true;
    }
    return false;
});

/* Нажатие кнопки сбросить фильтр */
$('body').on('click', '.js-filter-clear', function () {
    var params = [];
    params.push({
        'key': 'url',
        'value': $('div#filter').attr('data-url')
    });
    $('div#filter select').each(function () {
        $(this).val(0);
    });
    $('div#filter input:checked').each(function () {
        $(this).prop('checked', false);
    });
    $('div#filter input[data-control=rangeSlider]').each(function () {
        $(this).rangeSlider("updateSilent", {
            from: $(this).attr('data-min'),
            to: $(this).attr('data-max')
        });
    });
    products_request_ajax(params, undefined, undefined, true);
    $(this).closest('.navbar-offcanvas').trigger("offcanvas.close");
    return false;
});

$('body').on('click', '#toggle-filter-button', function () {
    $("#bs-example-navbar-collapse-2").trigger("offcanvas.open");
});

/* Нажатие кнопки применить фильтр */
$('body').on('click', '.js-filter-apply', function (e, scroll_up) {
    e.preventDefault();
    apply_products_filter();
    $(this).closest('.navbar-offcanvas').trigger("offcanvas.close");
    //setTimeout(function(){ location.reload(); }, 1500);
});

function request_filter_products_count() {
    var params = [];
    params.push({
        'key': 'url',
        'value': $('div#filter').attr('data-url')
    }, {
        'key': 'format',
        'value': 'products_count'
    });
    $('div#filter select').each(function () {
        if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
            params.push({
                'key': $(this).attr('name'),
                'value': $(this).val()
            });
    });
    $('div#filter input:checked:visible').each(function () {
        if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
            params.push({
                'key': $(this).attr('name'),
                'value': $(this).val()
            });
    });
    $('div#filter input[data-control=rangeSlider]').each(function () {
        if ($(this).val().length != 0 && $(this).val() != ($(this).attr('data-min') + ';' + $(this).attr('data-max')))
            params.push({
                'key': $(this).attr('name'),
                'value': $(this).val()
            });

    });
    products_request_ajax(params, undefined, undefined, true, false, false);
    return false;
}

$('body').on('change', '#main-container #filter input, #main-container #filter select', function () {
    /*С запросом количества товаров, удовлетворяющих фильтру*/
    /*request_filter_products_count();*/
    /*Моментальное применение фильтра*/
    /*$('#apply_products_filter').trigger('click', false);*/
    apply_products_filter();
    //setTimeout(function(){ location.reload(); }, 1500);
});

function rangeSliderStart() {
    $('input[data-control=rangeSlider]').rangeSlider({
        onChange: function (obj) {        // function-callback, is called on every change
			//console.log('actions_functions rangeSlider ' + obj.input.attr('name') + ' value=' + obj.input.val());
            if (!obj.input.data("disable")) {
                var i1 = $('#' + obj.input.attr('data-id') + "_min");
                var i2 = $('#' + obj.input.attr('data-id') + "_max");

                value_array = obj.input.val().split(";");
                i1.val(value_array[0]);
                i2.val(value_array[1]);
            }
        },
        onFinish: function (obj) {
            /*var params = [];
            params.push({
                'key': 'url',
                'value': $('div#filter').attr('data-url')
            },{
                'key': 'format',
                'value': 'products_count'
            });
            $('div#filter select').each(function(){
                if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
                    params.push({
                        'key': $(this).attr('name'),
                        'value': $(this).val()
                    });
            });
            $('div#filter input:checked:visible').each(function(){
                if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
                    params.push({
                        'key': $(this).attr('name'),
                        'value': $(this).val()
                    });
            });
            $('div#filter input[data-control=rangeSlider]').each(function(){
                if ($(this).val().length != 0 && $(this).val() != $(this).attr('data-min')+';'+$(this).attr('data-max'))
                    params.push({
                        'key': $(this).attr('name'),
                        'value': $(this).val()
                    });

            });
            products_request_ajax(params, undefined, undefined, true);*/

            /*$('#apply_products_filter').trigger('click', false);*/
            if ($(obj.input).closest('#main-container').length) {
                apply_products_filter();
            }
            //setTimeout(function(){ location.reload(); }, 1500);
        }
    });
	$('.range-need-hide').removeClass('in');
}

$('.container').on('click', 'div.selected-tags span.tag i.fa-times', function () {
    var mode = $(this).closest('span').data('mode');
    var name = $(this).closest('span').data('name');
    var tag = $(this).closest('span').data('tag');

    switch (mode) {
        case "range":
            var object = $('#filter input[name=' + name + ']');
            object.rangeSlider("updateSilent", {
                from: object.data('min'),
                to: object.data('max')
            });
			object.attr('data-from', object.data('min'));
			object.attr('data-to', object.data('max'));
			object.attr('data-property-filtered', "0");
            break;
        case "checkbox":
            var object = $('#filter input[name=' + name + '][value=' + tag + ']');
            object.prop('checked', false);
            break;
        case "radio":
            var object = $('#filter input[name=' + name + '][value=' + tag + ']');
            object.prop('checked', false);
            object = $('#filter input[name=' + name + '][value=0]');
            object.prop('checked', true);
            break;
        case "select":
            var object = $('#filter select[name=' + name + ']');
            object.val(0);
            break;
        case "logical":
            var object = $('#filter input[name=' + name + ']');
            object.prop('checked', false);
            break;
    }

    /*Запрос кол-ва товаров*/
    /*request_filter_products_count();*/
    /*$('#apply_products_filter').trigger('click', false);*/
    apply_products_filter();
    //setTimeout(function(){ location.reload(); }, 1500);
    $(this).closest('span').remove();

    return false;
});

$('body').on('keyup', 'input.rangeSlider', function () {
    var slider = $('#' + $(this).attr('data-object'));
    value_array = slider.val().split(";");
    var i1_value = parseInt(value_array[0]);
    var i2_value = parseInt(value_array[1]);
    var min_value = parseInt(slider.attr('data-min'));
    var max_value = parseInt(slider.attr('data-max'));
    var from_value = parseInt($('#' + slider.attr('data-id') + '_min').val());
    var to_value = parseInt($('#' + slider.attr('data-id') + '_max').val());

    var new_value = parseInt($(this).val());

    if ($(this).attr('data-control') == "from") {
        if (new_value > i2_value)
            new_value = i2_value;
        if (new_value < min_value || !new_value)
            new_value = min_value;
        if (i2_value != to_value && (new_value <= to_value) && (to_value <= max_value))
            i2_value = to_value;
        slider.rangeSlider("updateSilent", {
            from: new_value,
            to: i2_value
        });
		//console.log('updateSilentFrom');
    }
    if ($(this).attr('data-control') == "to") {
        if (new_value < i1_value)
            new_value = i1_value;
        if (new_value > max_value || !new_value)
            new_value = max_value;
        if (i1_value != from_value && (min_value <= from_value) && (from_value <= new_value))
            i1_value = from_value;
        slider.rangeSlider("updateSilent", {
            from: i1_value,
            to: new_value
        });
		//console.log('updateSilentTo');
    }
});

/* Изменение параметров фильтра
$('body').on('change', 'form#form_tags select', function(){
	//$('#apply_products_filter').trigger('click');
	apply_products_filter();
	return false;
});*/

/* Выбор бренда */
$('#brands').on('click', 'a', function (e) {

    if ($(this).attr('href') != '#') {
        if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
        {
            var win = window.open($(this).attr('href'), '_blank');
            return false;
        }

        $("body,html").animate({
            scrollTop: 0
        }, 0);

        var params = [];
        params.push({
            'key': 'url',
            'value': $(this).attr('href')
        });

        brand_request_ajax(params);
        return false;
    }
});

/* Переход по ссылке на карточку товара */
$('#main-right-container').on('click', 'a.producthref', function (e) {
    var url = $(this).attr('href');

    if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
    {
        var win = window.open(url, '_blank');
        return false;
    }

    product_request_ajax(url);
    return false;
});

/* Переход по ссылке на материал */
$('#main-right-container').on('click', 'a.materialhref', function (e) {
    var url = $(this).attr('href');

    if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
    {
        var win = window.open(url, '_blank');
        return false;
    }

    $("body,html").animate({
        scrollTop: 0
    }, 0);
    material_request_ajax(url);
    return false;
});

$('body').on('click', '#make-order, #cart', function (e) {

    if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
    {
        var win = window.open(mc_cart_module_url, '_blank');
        return false;
    }

    if ($('#cart').find('.cart-counter').length > 0)
        cart_request_ajax();
    return false;
});

/*
$('#make-compare').click(function(){
	compare_request_ajax();
	return false;
});
*/

/* развернуть вертикальное меню */
$('div.vertical-menu').on('click', 'i.fa-plus', function () {

    var li = $(this).closest('li');
    var ol = $(this).closest('div.vertical-menu');

    /* Свернем другие категории */
    /*$('ol.vertical-menu > li:not(.collapsed)').each(function()/*/
    ol.find('> li:not(.collapsed)').each(function () {
        var li_find = $(this).find('li[data-id=' + li.attr('data-id') + ']');
        if (li_find.length == 0) {
            var icon_minus = $(this).find('i.fa-minus');
            if (icon_minus.length > 0)
                icon_minus.trigger('click');
        }
    });

    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');
    var icon_spin = li.find('i.fa-spinner:first');

    icon_exp.hide();

    ol.show();
    icon_col.show();

    li.removeClass('collapsed');
});

/* свернуть вертикальное меню */
$('div.vertical-menu').on('click', 'i.fa-minus', function () {
    var li = $(this).closest('li');
    var ol = li.find('ol:first');
    var icon_col = li.find('i.fa-minus:first');
    var icon_exp = li.find('i.fa-plus:first');

    ol.hide();
    li.addClass('collapsed');
    icon_col.hide();
    icon_exp.show();
});

/* хлебные крошки */
$('#main-right-container').on('click', 'ol.breadcrumb a', function (e) {
    var url = $(this).attr('href');
    var type = $(this).attr('data-type');
    if (url == undefined)
        return false;

    if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
    {
        var win = window.open(url, '_blank');
        return false;
    }

    switch (type) {
        case 'category':
            var params = [{
                'key': 'url',
                'value': url
            }];
            products_request_ajax(params);
            break;
        case 'material-category':
            material_request_ajax(url);
            break;
        default:
            location.href = url;
            break;
    }

    return false;
});


$('ul.horizontal-menu a, #categories-menu-columns a').click(function (e) {
    var type = $(this).attr('data-type');
    var href = $(this).attr('href');

    if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
    {
        var win = window.open(href, '_blank');
        return false;
    }

    switch (type) {
        case 'category':
            document.location.href = href;
            //var params = [{ 'key': 'url', 'value': href}];
            //products_request_ajax(params);
            break;
        case 'product':
            product_request_ajax(href);
            break;
        case 'material':
            document.location.href = href;
            //material_request_ajax(href);
            break;
        case 'material-category':
            document.location.href = href;
            //material_request_ajax(href);
            break;
        case 'href':
            document.location.href = href;
            break;
        case 'sitemap':
            document.location.href = href;
            break;
        case 'reviews':
            document.location.href = href;
            break;
    }

    if (type != 'separator') {
        $(this).closest('ul').find('li.active').each(function () {
            $(this).removeClass('active');
        });

        $(this).closest('li').addClass('active');
    }
    return false;
});

$('ul.horizontal-menu li.dropdown, #categories-menu-columns li.dropdown').hover(function () {
    $(this).find('.dropdown-menu:first').stop(true, true).show();
}, function () {
    $(this).find('.dropdown-menu:first').stop(true, true).hide();
});

$('#searchButton').click(function () {

    var curli = $('#refreshpart div.active');
    if (curli.length > 0)
        curli.click();
    else {
        document.location.href = mc_products_module_url + '?search_keyword=' + $('#searchField').val() + '&format=search';
    }
    return false;
});

/* нажатие Enter в поле поиска */
$('#searchField').on('keydown', function (e) {
    if (e.keyCode == 13) {
        $('#categories').hide();
        $('#categories-menu-columns li.active').removeClass('active');

        $('#searchButton').trigger('click');
        return false;
    }
});

/* поиск keyword */
$('#searchField').toggleClass("filled", $('#searchField').val().length > 0).on('keyup', function (e) {
    var keyword = "";

    $('#searchCancelButton').toggle($(this).val().length > 0);
    $(this).toggleClass("filled", $(this).val().length > 0);

    /* down or up */
    if (e.keyCode == 40 || e.keyCode == 38) {
        var curli = $('#refreshpart div.active');

        if (e.keyCode == 40) {
            var nextli = curli.next('div[data-id]');
            if (nextli.length == 0) {
                var next_block = curli.closest('.search-block').next('.search-block');
                if (next_block.length == 0)
                    nextli = $('#refreshpart div[data-id]:first');
                else
                    nextli = next_block.find('div[data-id]:first');
            }

        }
        if (e.keyCode == 38) {
            var nextli = curli.prev('div[data-id]');
            if (nextli.length == 0) {
                var prev_block = curli.closest('.search-block').prev('.search-block');
                if (prev_block.length == 0)
                    nextli = $('#refreshpart div[data-id]:last');
                else
                    nextli = prev_block.find('div[data-id]:last');
            }
        }

        curli.removeClass('active');
        nextli.addClass('active');
    } else {
        if ($(this).val().length > 0) {
            /*$('#refreshpart').show();*/
            $('#searchCancelButton').show();
            keyword = $(this).val();

            if ($('#refreshpart').scrollTop() > 0)
                $('#refreshpart').scrollTop(0);
        } else {
            $('#refreshpart').hide();
        }
        search_request_ajax(keyword);
    }
});

$('#searchCancelButton').click(function () {
    $('#searchField').val('').removeClass("filled").focus();
    search_request_ajax();
    $('#refreshpart').hide();
    $(this).hide();
    return false;
});


$(document).on('click', '.expansible-block-toggler a', function (e) {
    e.preventDefault();
    var $o = $(this).closest(".expansible-block");
    var text = $(this).attr("data-label");
    $(this).attr("data-label", $(this).text());
    $(this).text(text);
    var id = $o.attr("id");
    if (!id) {
        id = "id" + (new Date()).getTime();
        $o.attr("id", id);
    }
    addCSSRule("rule-" + id, "#" + id + ".active { max-height: " + $o.find(".expansible-block-inner").outerHeight() + "px; }");
    $o.toggleClass("active");
});

$('#refreshpart').on('mouseenter', '.search-block-results div[data-id]', function () {
    $('#refreshpart .search-block-results div.active').removeClass('active');
    $(this).addClass('active');
});

$('#refreshpart').on('mouseleave', '.search-block-results div[data-id]', function () {
    $('#refreshpart .search-block-results div.active').removeClass('active');
});

$('#refreshpart').on("click", ".search-block-results div[data-id]", function () {
    var li = $(this);
    var object_id = li.attr('data-id');
    var object_type = li.attr('data-object');
    var object_href = li.attr('data-href');
    var object_name = li.find('.app-productname span').html();
    $('#searchField').val('');
    $('#searchField').blur();
    $('#refreshpart').hide();
    $('#searchCancelButton').hide();
    add_to_search_history(object_name);
    if (object_type == "product")
        product_request_ajax(object_href);
    if (object_type == "category") {
        /*var params = [{ 'key': 'url', 'value': object_href}];
        products_request_ajax(params);*/
        document.location.href = object_href;
    }
    if (object_type == "brand") {
        var params = [{'key': 'url', 'value': object_href}];
        brand_request_ajax(params);
    }
    if (object_type == "collection")
        document.location.href = object_href;
    if (object_type == "href")
        document.location.href = object_href;
    return false;
});

$('#searchcategories').on('click', 'a.menu61', function () {
    document.location.href = $(this).attr('href');
    return false;
    /*var params = [{ 'key': 'url', 'value': $(this).attr('href')}];
    products_request_ajax(params);
    return false;*/
});

$('#help_msg1, #help_msg2').click(function () {
    $('#searchField').val($(this).html());
    $('#searchField').trigger('keyup');
    return false;
});

$('body').on('click', '#filter label[data-toggle=collapse]', function () {
    $(this).closest('div').find('i.fa-caret-right').first().toggle();
    $(this).closest('div').find('i.fa-caret-down').first().toggle();
});

$('body').on('click', '#filter i', function () {
    $(this).closest('div').find('label.labelcollapse').trigger('click');
});

$('html').click(function () {
    $('#refreshpart').hide();
});

$('#refreshpart').click(function (event) {
    event.stopPropagation();
});

$('#main-left-container').on('click', '#pop-brands', function () {
    $(this).addClass('current');
    $('#all-brands').removeClass('current');
    $('#pop-brands-list').show();
    $('#all-brands-list').hide();
    $(this).closest(".left-brands").removeClass("wide");
    return false;
});

$('#main-left-container').on('click', '#all-brands', function () {
    $(this).addClass('current');
    $('#pop-brands').removeClass('current');
    $('#pop-brands-list').hide();
    $('#all-brands-list').show();
    $(this).closest(".left-brands").addClass("wide");
    return false;
});

$('#brands').on('click', '#pop-brands2', function () {
    $(this).addClass('current');
    $('#all-brands2').removeClass('current');
    $('#pop-brands-list2').show();
    $('#all-brands-list2').hide();
    $(this).closest(".left-brands").removeClass("wide");
    return false;
});

$('#brands').on('click', '#all-brands2', function () {
    $(this).addClass('current');
    $('#pop-brands2').removeClass('current');
    $('#pop-brands-list2').hide();
    $('#all-brands-list2').show();
    $(this).closest(".left-brands").addClass("wide");
    return false;
});

/* ФУНКЦИИ КОРЗИНЫ */
$('#main-right-container').on('click', 'li.favorite-item a.favorite-buy', function () {
    var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
    var var_amount = 1;/*$(this).attr('data-var-amount');*/
    var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=1&var_amount=' + var_amount;
    $.get(href, function (data) {
        if (data.success) {
            $('#cart').replaceWith(data.header);
            bbar_update(data.fbbar);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);
            $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
            $('#after-buy-form .modal-body').html(data.additional_result.added_form);
            $('#after-buy-form').modal();
			addVKReachGoalEvent(variant_id);
        }
    });
    return false;
});

$('#main-right-container').on('click', 'li.listitem a.list-buy', function () {
    /*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*/
    var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
    /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
    /*if ($(this).closest('li').find('input[name=variant_id]:checked').length > 0)
        var variant_id = $(this).closest('li').find('input[name=variant_id]:checked').val();
    else
        var variant_id = $(this).closest('li').find('input[name=variant_id][checked]').val();*/
    var var_amount = $(this).attr('data-var-amount');
    var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=1&var_amount=' + var_amount;
    $.get(href, function (data) {
        if (data.success) {
            $('#cart').replaceWith(data.header);
            bbar_update(data.fbbar);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);
            $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
            $('#after-buy-form .modal-body').html(data.additional_result.added_form);
            $('#after-buy-form').modal();
			addVKReachGoalEvent(variant_id);
        }
    });
    return false;
});

$('#main-right-container').on('click', 'li.plitka-item a.plitka-buy', function () {
    /*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*/
    var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
    /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
    /*if ($(this).closest('li').find('input[name=variant_id]:checked').length > 0)
        var variant_id = $(this).closest('li').find('input[name=variant_id]:checked').val();
    else
        var variant_id = $(this).closest('li').find('input[name=variant_id][checked]').val();*/
    var var_amount = $(this).attr('data-var-amount');
    var the_amount = $(this).attr('data-amount');
    if (the_amount === undefined) the_amount = 1
    var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + "&amount=" + the_amount + "&var_amount=" + var_amount;
    $.get(href, function (data) {
        if (data.success) {
            $('#cart').replaceWith(data.header);
            bbar_update(data.fbbar);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);
            $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
            $('#after-buy-form .modal-body').html(data.additional_result.added_form);
            $('#after-buy-form').modal();
			addVKReachGoalEvent(variant_id);
        }
    });
    return false;
});

$('#main-right-container').on('click', 'div.plitka-item a.plitka-buy', function () {
    /*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*/
    var variant_id = $(this).closest('div.plitka-item').find('select[name=variant_id] option:selected').val();
    /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
    /*if ($(this).closest('div.plitka-item').find('input[name=variant_id]:checked').length > 0)
        var variant_id = $(this).closest('div.plitka-item').find('input[name=variant_id]:checked').val();
    else
        var variant_id = $(this).closest('div.plitka-item').find('input[name=variant_id][checked]').val();*/
    var var_amount = $(this).attr('data-var-amount');
    var the_amount = $(this).attr('data-amount');
    if (the_amount === undefined) the_amount = 1;
    var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=' + the_amount + '&var_amount=' + var_amount;
    $.get(href, function (data) {
        if (data.success) {
            $('#cart').replaceWith(data.header);
            bbar_update(data.fbbar);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);
            $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
            $('#after-buy-form .modal-body').html(data.additional_result.added_form);
            $('#after-buy-form').modal();
			addVKReachGoalEvent(variant_id);
        }
    });
    return false;
});

$('body').on('click', 'a.add_to_compare', function () {
    var a = $(this);
    var href = a.attr('href');
    $.get(href, function (data) {
        if (data.success) {
            $('.js-make-compare').each(function(){
                $(this).attr('href', data.compare_href);
                $(this).html((($(this).data('prefix'))?$(this).data('prefix'):'') + data.compare_href_label);
                /*if (data.compare_href_show)
                    $(this).closest('div').show();
                else
                    $(this).closest('div').hide();*/
            });
            a.closest('.buy-compare').hide().next('.buy-compare').show().find('a.compare_items').attr('href', data.compare_href)/*.html('Сравнить ('+data.compare_items_count+')')*/;
            a.closest('ul.list').find('a.compare_items').each(function () {
                $(this).attr('href', data.compare_href);
                $(this).html('Сравнить (' + data.compare_items_count + ')');
            });
            bbar_update(data.fbbar);
        }
    });
    return false;
});


$('#main-right-container').on('click', 'a.remove_from_compare', function () {
    var a = $(this);
    var href = a.attr('href');
    $.get(href, function (data) {
        if (data.success) {
            $('.js-make-compare').each(function() {
                $(this).attr('href', data.compare_href);
                $(this).html((($(this).data('prefix'))?$(this).data('prefix'):'') + data.compare_href_label);
                /*if (data.compare_href_show)
                    $(this).closest('div').show();
                else
                    $(this).closest('div').hide();*/
            });
            a.closest('.buy-compare').hide().prev('.buy-compare').show();
            a.closest('ul.list').find('a.compare_items').each(function () {
                $(this).attr('href', data.compare_href);
                $(this).html('Сравнить (' + data.compare_items_count + ')');
            });
            bbar_update(data.fbbar);
        }
    });
    return false;
});

$('#wrap').on('click', 'a.cart-buy1click', function () {
    $('#cart').removeClass('hover');

    $.get(mc_cart_oneclick_module_url, function (data) {
        if (data.success) {
            $('#buy1click .modal-body .cart-body').html(data.data);
            $('#make-order-one-click').removeClass('disabled');
            $('#buy1click-phone-required').hide();

            if (data.total_price >= mc_cart_order_min_price) {
                $('#not-enough-block').hide();
                $('#make-order-one-click').show();
                $('#buy1click .buy1click-user-info').show();
            } else {
                $('#not-enough-block').show();
                $('#make-order-one-click').hide();
                $('#buy1click .buy1click-user-info').hide();
            }

            $('#buy1click').modal();
            $('#buy1click input[name=phone_number]').focus();
        }
    });

    return false;
});

$('#main-right-container').on('click', 'a.buy1-catalog', function () {
    var button = $(this);
    button.find('.buy1spinner').removeClass('hidden');
    button.attr('disabled', true);

    /*ЕСЛИ ВАРИАНТЫ СЕЛЕКТ*/
    if ($(this).closest('li').find('select[name=variant_id] option:selected').length > 0)
        var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
    else
        var variant_id = $(this).closest('div.plitka-item').find('select[name=variant_id] option:selected').val();
    /*if ($(this).closest('li').find('input[name=variant_id]:checked').length > 0)
        var variant_id = $(this).closest('li').find('input[name=variant_id]:checked').val();
    else
        var variant_id = $(this).closest('li').find('input[name=variant_id][checked]').val();*/
    var var_amount = $(this).attr('data-var-amount');
    var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=1&var_amount=' + var_amount;
    $.get(href, function (data) {
        if (data.success) {
            $('#cart').replaceWith(data.header);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);

            $.get(mc_cart_oneclick_module_url, function (data) {
                if (data.success) {
                    $('#buy1click .modal-body .cart-body').html(data.data);
                    $('#buy1click input[name=variant_id]').val(variant_id);
                    $('#make-order-one-click').removeClass('disabled');
                    $('#buy1click-phone-required').hide();

                    if (data.total_price >= mc_cart_order_min_price) {
                        $('#not-enough-block').hide();
                        $('#make-order-one-click').show();
                        $('#buy1click .buy1click-user-info').show();
                    } else {
                        $('#not-enough-block').show();
                        $('#make-order-one-click').hide();
                        $('#buy1click .buy1click-user-info').hide();
                    }

                    $('#buy1click').modal();
                    $('#buy1click input[name=phone_number]').focus();
                }
                button.find('.buy1spinner').addClass('hidden');
                button.attr('disabled', false);
            });
			
			addVKReachGoalEvent(variant_id);
        }
    });

    return false;
});

var buy_credit_working = false;
$('#main-right-container').on('click', 'a.buy-credit', function () {
    if (buy_credit_working)
        return false;
    buy_credit_working = true;
    var button = $(this);
    $('#buy1spinner-credit').removeClass('hidden');
    button.attr('disabled', true);
    /*ЕСЛИ ВАРИАНТЫ СЕЛЕКТ*/
    var variant_id = $(this).closest('#main-right-container').find('select[name=variant_id] option:selected').val();
    /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
    if ($(this).closest('#main-right-container').find('input[name^=variant_id]:checked').length > 0)
        var variant_id = $(this).closest('#main-right-container').find('input[name^=variant_id]:checked').val();
    else
        var variant_id = $(this).closest('#main-right-container').find('input[name^=variant_id][checked]').val();
    var modificators = [];
    var modificators_count = [];
    var amount = 1;
    var amount_input = $('#main-right-container input[name=amount]');
    if (amount_input.length > 0)
        amount = amount_input.val();


    /* ПОЛУЧИМ ВЫБРАННЫЕ ДОП КОМПЛЕКТАЦИИ */
    var dop_products = [];
    $('#dop-products .doplistitem input[name^=dop_products]:checked, #relatedvariants-products .doplistitem input[name^=dop_products]:checked').each(function () {
        dop_products.push($(this).closest('li.doplistitem').find('select[name=dop_variant_id] option:selected:first').val());
        //dop_products.push($(this).val());
    });

    $('.product-modifier input:checked, .product-modifier select option:selected').each(function () {
        if (parseInt($(this).val()) > 0) {
            modificators.push($(this).val());
            var ic = $(this).closest('div').find('input[name^=modificators_count]');
            if (ic.length > 0)
                modificators_count.push(ic.val());
            else
                modificators_count.push(1);
        }
    });

    /*var var_amount = 1;
    var var_amount_input = $('#buy input[name=var_amount]');
    if (var_amount_input.length > 0)
        var_amount = var_amount_input.val();
    var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&modificators=' + modificators.join(',') + '&modificators_count=' + modificators_count.join(',') + '&amount=' + amount + '&var_amount=' + var_amount;*/
    var href = mc_cart_module_url + "?action=add_variant&variant_id=" + variant_id + "&modificators=" + modificators.join(',') + "&modificators_count=" + modificators_count.join(',') + "&amount=" + amount;

    if (dop_products.length > 0)
        href += "&dop_products=" + dop_products.join(',');

    $.get(href, function (data) {
        buy_credit_working = false;
        if (data.success) {
            $('#cart').replaceWith(data.header);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);
            if (data.additional_result != undefined && data.additional_result.message == "no_stock") {
                $('.buy-buy').hide();
                $('.buy1').hide();
                $('.buy-credit').hide();
                $('#no_stock').html('Вы можете купить товар в количестве не более ' + data.additional_result.max_stock + ' штук');
                $('#no_stock').show();
            }
			
			addVKReachGoalEvent(variant_id);

            $.get(mc_cart_credit_oneclick_module_url, function (data) {
                if (data.success) {
					$('#buy1click-credit .modal-title').html(data.title);
                    $('#buy1click-credit .modal-body .cart-body').html(data.data);
                    $('#buy1click-credit input[name=variant_id]').val(variant_id);
                    $('#make-order-credit-one-click').removeClass('disabled');
                    $('#buy1click-credit-phone-required').hide();

                    if (data.total_price >= mc_cart_order_min_price) {
                        $('#not-enough-block').hide();
                        $('#make-order-credit-one-click').show();
                        $('#buy1click-credit .buy1click-credit-user-info').show();
                    } else {
                        $('#not-enough-block').show();
                        $('#make-order-credit-one-click').hide();
                        $('#buy1click-credit .buy1click-credit-user-info').hide();
                    }

                    $('#buy1click-credit').modal();
                    $('#buy1click-credit input[name=phone_number]').focus();
                }
                $('#buy1spinner-credit').addClass('hidden');
                button.attr('disabled', false);
            });
        }
    });

    return false;
});

var buy_one_click_working = false;
$('#main-right-container').on('click', 'a.buy-one-click', function () {
    if (buy_one_click_working)
        return false;
    buy_one_click_working = true;
    var button = $(this);
    button.find('.buy1spinner').removeClass('hidden');
    button.attr('disabled', true);

    //ЕСЛИ ВАРИАНТЫ СЕЛЕКТ
    var variant_id = $(this).closest('#main-right-container').find('select[name=variant_id] option:selected').val();
    //ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ
    if ($(this).closest('#main-right-container').find('input[name^=variant_id]:checked').length > 0)
        var variant_id = $(this).closest('#main-right-container').find('input[name^=variant_id]:checked').val();
    else
        var variant_id = $(this).closest('#main-right-container').find('input[name^=variant_id][checked]').val();
    var modificators = [];
    var modificators_count = [];
    var amount = 1;
    var amount_input = $('#main-right-container input[name=amount]');
    if (amount_input.length > 0)
        amount = amount_input.val();


    // ПОЛУЧИМ ВЫБРАННЫЕ ДОП КОМПЛЕКТАЦИИ
    var dop_products = [];
    $('#dop-products .doplistitem input[name^=dop_products]:checked, #relatedvariants-products .doplistitem input[name^=dop_products]:checked').each(function () {
        dop_products.push($(this).closest('li.doplistitem').find('select[name=dop_variant_id] option:selected:first').val());
        //dop_products.push($(this).val());
    });

    $('.product-modifier input:checked, .product-modifier select option:selected').each(function () {
        if (parseInt($(this).val()) > 0) {
            modificators.push($(this).val());
            var ic = $(this).closest('div').find('input[name^=modificators_count]');
            if (ic.length > 0)
                modificators_count.push(ic.val());
            else
                modificators_count.push(1);
        }
    });
    var href = mc_cart_module_url + "?action=add_variant&variant_id=" + variant_id + "&modificators=" + modificators.join(',') + "&modificators_count=" + modificators_count.join(',') + "&amount=" + amount;

    if (dop_products.length > 0)
        href += "&dop_products=" + dop_products.join(',');

    $.get(href, function (data) {
        buy_one_click_working = false;
        if (data.success) {
            $('#cart').replaceWith(data.header);
            $('#cart-module-top-line').html('<i class="fa fa-shopping-cart"></i> ' + data.total_products);
			
			addVKReachGoalEvent(variant_id);

            $.get(mc_cart_oneclick_module_url, function (data) {
                if (data.success) {
                    $('#buy1click .modal-body .cart-body').html(data.data);
                    $('#buy1click input[name=variant_id]').val(variant_id);
                    $('#make-order-one-click').removeClass('disabled');
                    $('#buy1click-phone-required').hide();

                    if (data.total_price >= mc_cart_order_min_price) {
                        $('#not-enough-block').hide();
                        $('#make-order-one-click').show();
                        $('#buy1click .buy1click-user-info').show();
                    } else {
                        $('#not-enough-block').show();
                        $('#make-order-one-click').hide();
                        $('#buy1click .buy1click-user-info').hide();
                    }

                    $('#buy1click').modal();
                    $('#buy1click input[name=phone_number]').focus();
                }
                button.find('.buy1spinner').addClass('hidden');
                button.attr('disabled', false);
            });
        }
    });

    return false;
});

$('#make-callback, #make-callback2, .fbbar__feedback').click(function () {
    //$('#contact-center input[name=phone_number]').val((mc_user_phone_code.length > 0 && mc_user_phone_w_mask.length > 0) ? '+7 (' + mc_user_phone_code + ')' + mc_user_phone_w_mask : '').mask("+7 (999) 999-99-99");
    $('#contact-center input[name=user_name]').val(mc_user_name);
    $('#contact-center input[name=call_time]').val('');
    $('#contact-center textarea[name=message]').val('');

    $('#callback-part').show();
    $('#send-callback').show();
    $('#callback-success-part').hide();
    $('#callback-error-part').hide();
    $('#callback-close').hide();
    $('#contact-center-phone-required').hide();

    $('#contact-center').modal();
    if ($('#contact-center input[name=phone_number]').val() == "")
        $('#contact-center input[name=phone_number]').focus();
    else if ($('#contact-center input[name=user_name]').val() == "")
        $('#contact-center input[name=user_name]').focus();
    else
        $('#contact-center input[name=call_time]').focus();
    return false;
});

$('#main-right-container').on('click', 'a.review-complain', function () {
    $('#claim-part').show();
    $('#make-claim').show();
    $('#claim-success-part').hide();
    $('#claim-error-part').hide();
    $('#claim-close').hide();

    $('#complain-form .modal-body textarea').val('').prop('disabled', true);
    $('#complain-form .modal-body input[type=radio]:first').prop('checked', true);
    $('#complain-form input[name=claim_review_id]').val($(this).closest('.review-body').data('id'));
    $('#complain-form').modal();
    return false;
});

$('#products-header-update-part .group-products-filter .group-products-filter-element').click(function () {
    document.location.href = $(this).find('a').attr('href');
    return false;
});

$('#main-right-container').on('click', '.block-class .group-products-filter .group-products-filter-element', function () {
    var type = $(this).data('type-id');
    $(this).closest('.group-products-filter').find('.group-products-filter-element').removeClass('active');
    $(this).addClass('active');

    /*var $owl = $(this).closest(".block-class").find(".owl-tiles");
    $all = $(this).closest(".block-class").find(".plitka-item").parent();
    if (!$owl.data("items-all")) $owl.data("items-all", $all);
    else $all = $owl.data("items-all");
    if (type) $filtered = $all.find(".plitka-item[data-type-id='" + type + "']").parent();
    else $filtered = $all;
    $filtered_clone = $filtered.clone(true);

    $all.each(function (i) {
        $owl.trigger('del.owl.carousel', 0);
    });
    $($filtered_clone.get().reverse()).each(function (i) {
        $owl.trigger('add.owl.carousel', [$(this), 0]);
    });
    $owl.trigger('update.owl.carousel');
    $owl.trigger('refresh.owl.carousel');*/
	
	$(this).closest(".block-class").find(".swiper-slide .plitka-item").each(function(idx, item){
		if (item.getAttribute('data-type-id') == type || type == 0)
			$(item.closest('.swiper-slide')).show();
		else
			$(item.closest('.swiper-slide')).hide();
	});
	
    return false;
});

$(document).on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', ".owl-tiles", function (event) {
    if (!event.namespace) return;
    var carousel = event.relatedTarget,
        element = event.target,
        current = carousel.current();
    //console.log(current === carousel.minimum(), current === carousel.maximum())
    $('.owl-next', element).toggleClass('disabled', current === carousel.maximum());
    $('.owl-prev', element).toggleClass('disabled', current === carousel.minimum());
});

/*
var mtophtmlint = setInterval(function(){
    if (typeof $("html").attr("mbr-initial-position") !== "undefined")
    {
        console.log($("html").css("margin-top"));
        m = parseInt($("html").css("margin-top"));
        if ($("html").data("mtop") != m)
        {
            $("body").css("margin-top", -1*m);
            $("html").data("mtopchanged", true)
        }
        else
        {
            if ($("html").data("mtopchanged") && m == 0) clearInterval($("html").data("mtophtmlint"));
            $("html")[0].style.setProperty('margin-top', '0', 'important');
        }
        $("html").data("mtop", m);
    }
}, 10);
$("html").data("mtophtmlint", mtophtmlint);*/

$(window).on("load", function () {

    lazy_load();
    responsive_update();

    $(window).on("scroll", function () {

        $(".popover-show-on-scroll").each(function () {
            if (!$(this).hasClass("in") && $(this).offset().top < $(window).scrollTop() + $(window).height() / 4 * 3) {
                var $this = $(this);
                setTimeout(function () {
                    $this.addClass("in");
                }, 1500);
            }
        });

        lazy_load();

    });

    //bind_widgets();
    //$(document).ajaxStop(function () {
    //    bind_widgets();
    //});

});

$(window).on("resize orientationchange", function (e) {
    responsive_update();
    filter_update();
});

$(window).on("scroll", function (e) {
    if ($(window).scrollTop() > 0) $("body").addClass("scrolled");
    else $("body").removeClass("scrolled");
});

function bind_widgets() {
    //owl_init();
    jscroll_init();

    $(".filterbox .nicescroll").each(function () {
        var $checkbox = $(this).find(":checkbox:checked:first").closest(".checkbox");
        if ($checkbox.length) {
            var id = $(this).closest(".filterbox").attr("id");
            var pos = $("body").data("scrollto_id_" + id + "_pos");
            if (pos) {
                $(this).scrollTop(pos);
                if (jQuery().niceScroll) $(this).getNiceScroll(0).doScrollTop(pos, 0);
            }
        }
    });
}

function jscroll_init($obj) {
    if (!jQuery().niceScroll) return;
    //if (Modernizr.touch) return;
    if (typeof $obj === "undefined") $obj = $(".nicescroll");
    if ($obj.length) {
        $obj.not(".nicescroll--inited").each(function () {
            var $this = $(this);
            var options = {
                autohidemode: false,
                grabcursorenabled: false,
                cursorborder: 0,
                cursorfixedheight: (typeof $this.data("cursorfixedheight") !== "undefined") ? $this.data("cursorfixedheight") : false,
                cursorwidth: "4px",
                railpadding: jscroll_get_rail_padding($this),
                preventmultitouchscrolling: false,
                mousescrollstep: (typeof $this.data("mousescrollstep") !== "undefined") ? $this.data("mousescrollstep") : 40,
                cursordragontouch: true,
                touchbehavior: false,
                bouncescroll: false,
                horizrailenabled: (typeof $this.data("horizrailenabled") !== "undefined") ? $this.data("horizrailenabled") : false
            };
            if ($(this).find("> .scrollable-inner").length) var $scroll = $this.niceScroll($this.find("> .scrollable-inner"), options);
            else var $scroll = $this.niceScroll(options);
            var ids = [$scroll.id];
            if ($("#" + $scroll.id + "-hr").length) {
                ids.push($scroll.id + "-hr");
            }
            $.each(ids, function (k, v) {
                $("#" + v).addClass("nicescroll-rails--inited");
                if ($this.attr("data-nicescroll-class")) $("#" + v).addClass($this.attr("data-nicescroll-class"));
                $obj.attr("data-nicescroll-id", v);
            });
            $(this).addClass("nicescroll--inited");
        });
    }
}

function jscroll_update() {
    $(".nicescroll-rails-vr").each(function () {
        $(".nicescroll--inited").each(function () {
            var nicescroll = $(this).getNiceScroll();
            $.extend(nicescroll[0].opt, {
                railpadding: jscroll_get_rail_padding($(this))
            });
        });
    });
}

function jscroll_get_rail_padding($o) {
    var default_top = 0;
    var default_bottom = 0;
    var default_left = 0;
    var default_right = 0;
    var railptop = 0;
    var railpbottom = 0;
    var railpleft = 0;
    var railpright = 0;
    railptop = ($o.attr("data-railpadding-top")) ? 1 * $o.attr("data-railpadding-top") : default_top;
    railpbottom = ($o.attr("data-railpadding-bottom")) ? 1 * $o.attr("data-railpadding-bottom") : default_bottom;
    if ($o.hasClass("scrollable-x")) railpleft = ($o.attr("data-railpadding-left")) ? 1 * $o.attr("data-railpadding-left") : default_left;
    if ($o.hasClass("scrollable-x")) railpright = ($o.attr("data-railpadding-right")) ? 1 * $o.attr("data-railpadding-right") : default_right;
    var obj = {top: railptop, right: railpright, left: railpleft, bottom: railpbottom};
    return obj;
}

function owl_init() {
    $(".owl-tiles:not(.inited)").each(function () {
		//console.log('owl init');
		//alert('in');
        var start = +new Date();  // log start timestamp
        $(this).addClass("inited").owlCarousel({
            smartSpeed: (typeof $(this).data("owl-smart-speed") !== "undefined") ? $(this).data("owl-smart-speed") : 300,
            loop: false,
            dots: (typeof $(this).data("owl-dots") !== "undefined") ? !!$(this).data("owl-dots") : false,
            items: $(this).data("owl-items") ? $(this).data("owl-items") : 4,
			responsiveClass:true,
            responsive: ($(this).data("owl-responsive")) ? $(this).data("owl-responsive") : {
                0: {
                    items: 1,
					nav: true,
                },
                539: {
                    items: 2,
					nav: true,
                },
                767: {
                    items: 3,
					nav: true,
                },
                999: {
                    items: 4,
					nav: true,
                }
            },
            touchDrag: (typeof $(this).data("owl-touch-drag") !== "undefined") ? !!$(this).data("owl-touch-drag") : true,
            pullDrag: (typeof $(this).data("owl-pull-drag") !== "undefined") ? !!$(this).data("owl-pull-drag") : true,
            mouseDrag: (typeof $(this).data("owl-mouse-drag") !== "undefined") ? !!$(this).data("owl-mouse-drag") : true,
            freeDrag: (typeof $(this).data("owl-free-drag") !== "undefined") ? !!$(this).data("owl-free-drag") : false,
            autoWidth: (typeof $(this).data("owl-autowidth") !== "undefined") ? !!$(this).data("owl-autowidth") : false,
            nav: true,
            slideBy: $(this).data("owl-slide-by") ? $(this).data("owl-slide-by") : 1,
            navText: ['<', '>']
        });
        var end = +new Date();  // log end timestamp
        var diff = end - start;
        //console.log(diff);
    });
}

function lazy_load() {
    $(".lazy-load").not(".lazy-load-inited").each(function () {
        if ($(this).offset().top - $(window).height() < $(window).scrollTop() + $(window).height()) {
            lazy_load_change($(this));
        }
    });
}

function lazy_load_change($img) {
    $img.addClass("lazy-load-inited");
    $img.attr("src", $img.attr("data-lazy-src"));
    $img.one("load", function () {
        var $this = $(this);
    });
}


function category_banner_init() {
    category_banner_update();
    category_banner_scroll();

    $(window).on("load resize orientationchange", function (e) {
        category_banner_update();
    });

    $(window).on("scroll", function (e) {
        category_banner_scroll();
    });
}

function category_banner_update() {
    $(".category-banner-side").css("top", parseInt($(".category-banner-side:first").css("padding-top"), 10) + 25);//$(".topline").offset().top + $(".topline").outerHeight());
}

function category_banner_scroll() {
    if ($(window).scrollTop() > parseInt($(".category-banner-side:first").css("padding-top"), 10) + 25) // if ($(window).scrollTop() > $(".topline").offset().top + $(".topline").outerHeight())
    {
        if (!$(".category-banner-side:first").hasClass("fixed")) $(".category-banner-side").addClass("fixed");
    } else {
        if ($(".category-banner-side:first").hasClass("fixed")) $(".category-banner-side").removeClass("fixed");
    }

    if ($(window).scrollTop() > 144) {
        if (!$(".menu-switcher.filter-switcher").hasClass("fixed")) $(".menu-switcher.filter-switcher").addClass("fixed");
    } else {
        if ($(".menu-switcher.filter-switcher").hasClass("fixed")) $(".menu-switcher.filter-switcher").removeClass("fixed");
    }

    if ($(window).scrollTop() > 54) {
        if (!$(".wrap.wrap-top-menu").hasClass("fixed")) {
            $(".wrap.wrap-top-menu").addClass("fixed");
            $(".wrap.wrap-top-menu #cart-module-top-line").addClass("fixed");
        }
    } else {
        if ($(".wrap.wrap-top-menu").hasClass("fixed")) {
            $(".wrap.wrap-top-menu").removeClass("fixed");
            $(".wrap.wrap-top-menu #cart-module-top-line").removeClass("fixed");
        }
    }
}


function search_toggler_init() {
    $(document).on("click", "#search", function () {
        $(this).addClass("active");
    });
    $(document).on("click", ".search-hide", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#search").removeClass("active");
    });
    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest("#search").length) $("#search").removeClass("active");
    });
}

function phone_toggler_init() {
    $(document).on("click", ".phone-switcher", function (e) {
        e.preventDefault();
        $(".phone").toggleClass("active");
    });
    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".phone, .phone-switcher").length) $(".phone").removeClass("active");
    });
}


var addCSSRule = function (sheet_id, rules) {
    $("#" + sheet_id).remove();
    $("<style type='text/css' id='" + sheet_id + "'>" + rules + "</style>").appendTo("head");
};

var removeCSSRule = function (sheet_id) {
    $("#" + sheet_id).remove();
};


/*

Responsive design helpers

*/

function responsive_init() {
    responsive_update();

    $(".decription-category-top-to-bottom").each(function () {
        if (!$(this).prev("#products-update-block").length) {
            $(this).insertAfter("#products-update-block");
        }
    });

    var $allVideos = $("#main-right-container iframe").not('.do-not-resize');
    $allVideos.each(function () {
		if (!$(this).hasClass('do-not-resize')) {
			$(this)
				.data('aspectRatio', this.height / this.width)
				.removeAttr('height')
				.removeAttr('width');
		}
    });

    $(window).on("resize orientationchange", function () {
        $allVideos.each(function () {
            var $el = $(this);
            var newWidth = $el.parent().width();
            $el
                .width(newWidth)
                .height(newWidth * $el.data('aspectRatio'));
        });
    }).resize();


    $(".ordertable tbody tr").each(function (i) {
        $(this).find("td").each(function (j) {
            $(this).prepend("<div class='ordertable-label'>" + $(this).closest(".ordertable").find("thead th").eq(j).text() + ": </div>")
        });
    });


    $(document).on("click", ".touch .touch-focused", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused") || $o.hasClass("touch-focused-toggle")) {
            e.preventDefault();
            e.stopPropagation();
            if ($o.closest(".touch-focused-container").length) $o.closest(".touch-focused-container").find(".touch-focused").not($o).removeClass("focused");
            else $(".touch-focused").not($o).removeClass("focused");
            $o.toggleClass("focused");
        }
    });

    $(document).on("click touchstart", ".touch *", function (e) {
        if (!$(e.target).closest(".touch-focused").length) $(".touch-focused").removeClass("focused");
    });

    $(document).on("click", ".phone-toggle", function (e) {
        $(".phone").toggleClass("active");
    });

    $(document).on("click maxheight-init", ".slideout-menu-list li.expanded > a, .slideout-menu-list li.expanded > span", function (e) {
        var $o = $(this).closest("li");
        //console.log("click maxheight-init");
        e.preventDefault();
        if ($o.find("> ul, > div > ul").length) {
            var id = $o.attr("id");
            if (!id) {
                id = "id" + (new Date()).getTime();
                $o.attr("id", id);
            }
            height = $o.find("> .slideout-menu-list-submenu > ul").outerHeight();
            //console.log("height", height);
            if (height) addCSSRule("rule-" + id, "#" + id + ".hovered > .slideout-menu-list-submenu { max-height: " + height + "px; }");
            $o.siblings().removeClass("hovered").find(".slideout-menu-list-submenu").removeClass("overflow-visible");
            if ($o.hasClass("hovered")) $o.find(".slideout-menu-list-submenu").removeClass("overflow-visible");
            $o[0].offsetHeight;
            if (e.type != "maxheight-init") {
                $o.toggleClass("hovered").siblings().removeClass("hovered");
            }
        } else {
            if (e.type != "maxheight-init") slideout.close();
        }
    });

    $(".slideout-menu-list li.expanded > a, .slideout-menu-list li.expanded > span").trigger("maxheight-init");

    $(document).on("transitionend -webkit-transitionend", ".slideout-menu-list-submenu", function (e) {
        var $o = $(this).closest("li");
        if ($o.hasClass("hovered")) $(this).addClass("overflow-visible");
    });

    $(".materials-menu").clone().appendTo("#h1-sort-header-block");

    $(document).on("click", ".materials-menu-toggle", function (e) {
        $(this).toggleClass("active");
        $(this).siblings(".materials-menu").toggleClass("active");
    });

    $(document).on("click touchstart", "body", function (e) {
        if (!$(e.target).closest(".materials-menu").length && !$(e.target).closest(".materials-menu-toggle").length) {
            $(".materials-menu-toggle").removeClass("active");
            $(".materials-menu").removeClass("active");
        }
    });

    /*$(document).on("click", ".filter-toggle", function (e) {
        $(this).toggleClass("active");
        $("#filter").toggleClass("active");
    });*/
/*
    filter_position_update();
    $(window).on("resize orientationchange", function () {
        filter_position_update();
    });*/

    /*$(document).on("click touchstart", "body", function (e) {
        if (!$(e.target).closest("#filter").length && !$(e.target).closest(".filter-toggle").length) {
            $(".filter-toggle").removeClass("active");
            $("#filter").removeClass("active");
        }
    });*/
}

/*function filter_position_update() {
    if ($(".filter-switcher").next("h1").length) $(".filter-switcher").toggleClass("position-2", $(".filter-switcher").next("h1").height() > 30);
}*/

function responsive_update() {
    /*if($('#fil_mob #filter').length) console.log("s");
    else if(!$('#fil_mob #filter').length) console.log("ns");*/
    if (window.matchMedia("(min-width: 1000px)").matches) {
        //$(".header-right .topline-links").prependTo(".header-right");
    } else {
        //$(".header-right .topline-links").appendTo(".header-right");
    }
    if (window.matchMedia("(min-width: 768px)").matches) {
        //console.log("> 767");
        //$("#filter").insertAfter("#searchcategories");
        // $(".under-filter").insertAfter("#filter");
        // $(".found-subcategories").insertBefore("#products-update-block");
        $("#filter").insertBefore(".filter-place");
    } else {
        //console.log("! > 767");
        //$("#filter").appendTo("#h1-sort-header-block");
        $("#filter").appendTo("#fil_mob");
        //$(".under-filter").insertAfter("#main-container");
        //$(".found-subcategories").insertAfter("#main-container");
    }

    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function () {
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function (value) {
                return parseInt(value);
            }).sort(function (a, b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: " + breakpoints[i] + "px)").matches) {
                    if ($(places[breakpoints[i]]).length) {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("body").trigger("responsive-update");

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}

/* Fbbar */

$('#fbbar .disabled').on('click', function (e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
});

function bbar_init() {
    $(".fbbar-replace").height($(".fbbar").height());

    $(document).on("mouseenter", ".no-touch .fbbar__cell--cart", function (e) {
        if (!$(this).hasClass('disabled')) {
            delay_bbar(function () {
                bbar_show();
            }, 300);
        }
    });

    $(document).on("mouseleave", ".no-touch .fbbar__cell--cart", function (e) {
        delay_bbar(function () {
            bbar_hide();
        }, 500);
    });

    $(document).on("focus", ".fbbar__cart input", function (e) {
        bbar_focus();
        if (bbar_hidden()) {
            delay_bbar(function () {
                bbar_show();
            }, 0);
        }
    });

    $(document).on("blur", ".fbbar__cart input", function (e) {
        bbar_blur();
    });

    $(document).on("change paste keyup", ".fbbar__cart input", function (e) {
        debouncedSetAmount($(this).attr('data-position'), this.value, true);
    });

    $(document).on("click touchstart", ".fbbar__scart", function (e) {
        delay_bbar(function () {
            bbar_show();
        }, 0);
    });

    $(document).on("click", "body", function (e) {
        if (!$(e.target).closest(".fbbar__cell--cart").length) {
            bbar_hide();
        }
    });

    $(document).on("click", ".js-cart-close", function (e) {
        e.preventDefault();
        bbar_hide();
    });

    $(document).on("click", ".js-cart-item-delete", function (e) {
        var position = $(this).attr('data-id');
        removeCartItem(position, function (data) {
            if (data.success) {
                $(this).closest('.js-fbbar_item').remove();
                bbar_update_prices();
                $('#cart').replaceWith(data.header);
                bbar_update(data.fbbar);
                if (!data.total_products) bbar_hide();
            }
        });
        return false;
    });

    $(window).on("scroll resize orientationchange", function (e) {
        var $replace = $(".fbbar-wrapper");
        var $bar = $(".fbbar");
        if ($bar.length && $replace.length) {
            if (!$bar.hasClass("absolute-bottom") && $(window).scrollTop() > $replace.offset().top - $(window).height() + $bar.outerHeight()) {
                $bar.addClass("absolute-bottom");
            }
            if ($bar.hasClass("absolute-bottom") && $(window).scrollTop() <= $replace.offset().top - $(window).height() + $bar.outerHeight()) {
                $bar.removeClass("absolute-bottom");
            }
        }
    });
}

removeCartItem = function (position, callback) {
    $('#preloader').fadeIn(100);

    var href = "/cart/?action=remove_variant&variant_id=" + position;
    $.ajax({
        method: 'GET',
        url: href,
        success: function (data) {
            callback(data);
        }
    }).complete(function () {
        $('#preloader').fadeOut(0);
    });

    return false;
}

var bbar_show = function() {
    var $cart = $(".fbbar__cart");
    height = $cart.find(".fbbar__cart-inner").outerHeight();
    addCSSRule("rule-fbbar-cart", ".fbbar__cart.active { max-height: " + height + "px; }");
    $cart[0].offsetHeight;
    $cart.addClass("active");
}

var bbar_hide = function() {
    if (!bbar_focused()) $(".fbbar__cart").removeClass("active");
}

function bbar_hidden() {
    return !$(".fbbar__cart").hasClass("active");
}

function bbar_focus() {
    $(".fbbar__cart").addClass("focus");
}

function bbar_blur() {
    $(".fbbar__cart").removeClass("focus");
}

function bbar_focused() {
    return $(".fbbar__cart").hasClass("focus");
}

var bbar_update = function(data) {
    var bar_hidden = bbar_hidden();
    $('#fbbar').replaceWith(data);
    if (!bar_hidden) {
        bbar_show();
    }
}

var bbar_update_prices = function() {
    var $fbbar = $('#fbbar'),
        $cartTotalSum = $fbbar.find('.js-fbbar_cart_total'),
        $cartQuantity = $fbbar.find('.js-fbbar_cart_quantity'),
        $cartItems = $fbbar.find('.js-fbbar_item'),
        sum = 0, quantity = 0;
    $.each($cartItems, function () {
        var $item = $(this),
            $itemQuantityInput = $item.find('.js-fbbar_item_quantity'),
            $itemPriceTotal = $item.find('.js-fbbar_item_price_total'),
            itemPrice = parseFloat($itemQuantityInput.data('item-price')),
            itemAddPrice = parseFloat($itemQuantityInput.data('item-add-price')),
            itemQuantity = parseFloat($itemQuantityInput.val());
        quantity += itemQuantity;
        var itemPriceTotal = Math.ceil(itemQuantity * itemPrice + itemAddPrice);
        $itemPriceTotal.text(itemPriceTotal.toLocaleString('ru'));  // russian has proper thousand separator
        sum += itemPriceTotal;
    });
    $cartTotalSum.text(sum.toLocaleString('ru'));  // russian has proper thousand separator
    $cartQuantity.text(quantity);
}

var delay_bbar = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

/*var amount_ajax_context;
function set_amount(variant_id, amount, update_bar){

	if (amount_ajax_context != null)
		amount_ajax_context.abort();

	if (update_bar) {
		bbar_update_prices();
	}
	amount_ajax_context = $.get('/cart/?action=set_amount&variant_id='+variant_id+'&amount='+amount, function(data){
		if (data.success)
		{
			$('#cart').replaceWith(data.header);
			if (update_bar) {
				bbar_update(data.fbbar);
			}
		}
		amount_ajax_context = null;
	});
}*/

/*
var debouncedSetAmount = $.debounce(1000, function(variant_id, amount, update_bar) {
	return set_amount(variant_id, amount, update_bar);
});//функция запускается с отсрочкой в 250 мс, если не будет вызвана вновь
*/

openInfo = function (el) {
    $('#preloader').fadeIn(100);
    var $el = $(el);
    var variant_id = $el.closest('.plitka-item, .listitem').find('input[name=product_id]').val();
    var href = "/catalog/?information_products=" + variant_id;
    $.get(href, function (data) {
        $('#after-information-products .modal-body').html(data.data);
        $('#after-information-products .modal-footer-col a').attr("href", data.href);
        $('#after-information-products').modal();
        $('#after-information-products .mobx-prev').val(variant_id);
        $('#after-information-products .mobx-next').val(variant_id);

    }).complete(function () {
        $('#preloader').fadeOut(0);
    });
    return false;
}

$('.one-click-product-card').off('click');
$('body').on('click', '.one-click-product-card', function (e) {
    e.preventDefault();
    e.stopPropagation();
    //console.log('click listener');
    return false;
});


/*
* SVG icon url update
* */

function icons_load() {
    var url = $('body').data('svg-sprite-url');
    $.ajax({
        url: url,
        localCache: true,
        cacheTTL: 1,
        dataType: 'text',
        cacheKey: 'svg-sprite'
    }).done(function (response) {
        $('body').append('<div id="svg-icons-container" style="height: 0; width: 0; position: absolute; top: -99999px; left: 0; visibility: hidden;">' + response + '</div>');
    });
}


/*

Scroll Lazy Loading

 */

function blazy_init() {

    $('.js-blazy-revalidate-on-scroll').on('scroll', function () {
        blazy_revalidate_on_scroll_delay(function () {
            if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                Blazy.revalidate();
            }
        }, 50);
    });

    window.Blazy = new Blazy({
        offset: 0,
        success: function (ele) {
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
            //console.log('Blazy', ele, 'success');
            blazy_callback(ele);
        }
        , error: function (ele, msg) {
            //console.log('Blazy', ele, msg);
            blazy_callback(ele);
            if (msg === 'missing') {
                //console.log(ele, msg);
            } else if (msg === 'invalid') {
                //console.log(ele, msg);
            }
        }
    });

    $(window).on('resize', function () {
        blazy_revalidate_delay(function () {
            blazy_revalidate();
        }, 100);
    });

    $(document).on('shown.bs.modal', function () {
        blazy_revalidate();
    });

    $('.js-slick-slider-revalidate-image').on('init reInit setPosition', function (event) {
        blazy_revalidate();
    });
}

function blazy_callback(ele) {
    var callback = $(ele).not('.blazy-callback-executed').data('src-callback');
    if (callback) {
        var callback_params = $(ele).data('src-callback-params');
        if (!callback_params) {
            callback_params = [];
        }
        var fn = getFunctionFromString(callback);
        if (typeof fn === "function") {
            $(ele).addClass('blazy-callback-executed');
            fn.apply(ele, callback_params);
        }
    }
}

function blazy_revalidate() {
    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
        Blazy.revalidate();
    }
}

var blazy_revalidate_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var blazy_revalidate_on_scroll_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

// Get function from string, with or without scopes (by Nicolas Gauthier)
window.getFunctionFromString = function (string) {
    var scope = window;
    var scopeSplit = string.split('.');
    for (i = 0; i < scopeSplit.length - 1; i++) {
        scope = scope[scopeSplit[i]];

        if (scope == undefined) return;
    }

    return scope[scopeSplit[scopeSplit.length - 1]];
};


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited")) {

        $(window).on("resize", function () {
            delay_img_to_bg(function () {
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = "";
        if ($img.length) {
            var src = $img[0].currentSrc;
        }
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();



/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .js-touch-focused, .js-touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".js-touch-focused").not($o).not($o.closest(".js-touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
        else {
            if ($o.hasClass('js-touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .js-touch-focused a, .js-touch-focused-all a", function (e) {
        var $tf = $(this).closest(".js-touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".js-touch-focused").length) {
            $(".js-touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".js-prevent-default", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".js-stop-propagation", function (e) {
        e.stopPropagation();
    });

    $(document).on("click", ".touchevents .js-prevent-default-on-touch", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".touchevents .js-stop-propagation-on-touch", function (e) {
        e.stopPropagation();
    });

}




/*

Hide/Show blocks

 */

function toggle_element_init()
{
    $(document).on("click click-pseudo change toggle-hide", ".toggle-element, .show-element, .hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        var restore_scroll = $(this).data("toggle-restore-scroll-position");
        if (restore_scroll) {
            var scroll_pos = $(window).scrollTop();
            var $scroll_relative = $(this);
            var scroll_relative = $(this).data('toggle-restore-scroll-relative');
            if (scroll_relative) {
                $scroll_relative = $(scroll_relative);
            }
            var offset_pos = parseInt($scroll_relative.offset().top, 10);
        }
        var class_name = "hidden";
        if ($(this).data("toggle-class")) {
            class_name = $(this).data("toggle-class");
        }
        var element_class_name = "active";
        if ($(this).data("toggle-element-class")) {
            element_class_name = $(this).data("toggle-element-class");
        }
        var change_label = false;
        if (($(this).hasClass("toggle-element") && e.type != "toggle-hide") || (e.type == "toggle-hide" && $o.hasClass(element_class_name))) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            }
            else {
                $(this).toggleClass(element_class_name);
                $o.toggleClass(class_name);
            }
            $o.data('toggle-class', class_name);
            $o.data('toggle-element-class', element_class_name);
            $o.data('toggle-element', $(this));
            $o.trigger("toggle-element");
        }
        if ($(this).data("toggle-close-other") && e.type != "toggle-hide") {
            var group = $(this).data("toggle-group");
            $('.toggle-element, .show-element').not(this).filter('[data-toggle-group="'+group+'"]').each(function(){
                $(this).trigger('toggle-hide');
            });
        }
        if ($(this).hasClass("show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !!$(this).data("toggle-reverse"));
            $o.trigger("show-element");
        }
        if ($(this).hasClass("hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.trigger("hide-element");
        }
        if (change_label && $(this).data("toggle-change-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-toggle-change-label"));
            $(this).attr("data-toggle-change-label", label);
        }
        var $siblings = $(this).siblings();
        if ($(this).data("toggle-siblings-selector")) {
            $siblings = $(this).closest($(this).data("toggle-siblings-selector")).siblings();
        }
        if ($(this).data("toggle-current-selector")) {
            $siblings = $siblings.find($(this).data("toggle-current-selector"));
        }
        $siblings.removeClass(element_class_name);
        if (restore_scroll) {
            var scroll_pos_new = $(window).scrollTop();
            var offset_pos_new = parseInt($scroll_relative.offset().top, 10);
            $(window).scrollTop(offset_pos_new - (offset_pos - scroll_pos));
        }
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".toggle-element").length && !$(e.target).closest(".toggle-element-object").length) {
            $(".toggle-element-object[data-toggle-element-close-on-blur]").each(function(){
                var classname = $(this).data('toggle-class');
                if (!classname) classname = 'hidden';
                $(this).removeClass(classname);
                if ($(this).data('toggle-element')) {
                    $($(this).data('toggle-element')).removeClass($(this).data('toggle-element-class'));
                }
            });
            //e.stopPropagation();
        }
    });
}

$('#city-location').click(function(){
	$('#select-region').modal();
});
$('#city-location-mobile').click(function(){
	$('#select-region').modal();
});

function region_confirm_popover(){
	if (!mc_user_selected_region && mc_user_region_domain_available)
		if ($('#city-location').is(":visible"))
			confirm_region_popover_box($('#city-location'));
		else
			confirm_region_popover_box($('#city-location-mobile'));
}

$('.property-help').click(function() {
    //console.log('prevent');
	return false;
  });